import React from "react";
import cvFile from "../assets/images/cv.webp";
import {
  FaEnvelopeOpenText,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaDiscord,
  FaGithub,
} from "react-icons/fa";
import img from "../assets/images/about/avatar.jpg";
import downloadIcon from "../assets/images/download.png";

const HomeCard = () => {
  return (
    <div>
      <div className="relative mx-auto mb-6  mt-[180px]   w-full rounded-[20px] bg-white px-6 text-center dark:bg-[#111111] md:mt-[220px] lg:mb-0 lg:mt-0 ">
        <img
          src={img}
          className="absolute left-[50%] mx-auto -mt-[140px] h-[240px] w-[240px] -translate-x-[50%] transform  rounded-[20px] drop-shadow-xl"
          alt=""
        />
        {/* Social card */}
        <div className="pb-8 pt-[100px]">
          <h1 className="mb-1 mt-6 text-5xl font-semibold  dark:text-white">
            Muhammet Güngören
          </h1>
          <h3 className="mb-4 inline-block rounded-lg px-5 py-1.5 text-[#7B7B7B] dark:bg-[#1D1D1D] dark:text-[#A6A6A6]  ">
            Computer Programmer
          </h3>

          {/* Social Links */}

          <div className="flex justify-center space-x-3">
            {/* facebook link add here */}
            <a
              href="https://github.com/M3M4Ti"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#1773EA]">
                <FaGithub />
              </span>
            </a>
            {/* linkedin link add here */}
            <a
              href="https://www.linkedin.com/in/muhammet-gungoren/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="socialbtn text-[#0072b1]">
                <FaLinkedinIn />
              </span>
            </a>
          </div>

          {/* personal information */}
          <div className="mt-7 rounded-2xl bg-[#F3F6F6]  p-7 dark:bg-[#1D1D1D]">
            <div className="flex border-b border-[#E3E3E3] pb-2.5 dark:border-[#3D3A3A]">
              <span className="socialbtn flex-shrink-0 bg-white text-[#E93B81] shadow-md dark:bg-black">
                <FaDiscord />
              </span>
              <div className="ml-2.5 text-left">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Discord
                </p>
                <p className="break-all dark:text-white">
                  {" "}
                  <a
                    className="transition duration-300 hover:text-[#FA5252]"
                    href="https://discordapp.com/users/471640166759661568"
                  >
                    m3m4ti
                  </a>
                </p>
              </div>
            </div>

            <div className="flex  border-b border-[#E3E3E3] py-2.5 dark:border-[#3D3A3A]">
              <span className="socialbtn flex-shrink-0 bg-white text-[#6AB5B9] shadow-md dark:bg-black">
                <FaEnvelopeOpenText />
              </span>
              <div className="ml-2.5 text-left">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Email
                </p>
                <p className="break-all dark:text-white">
                  {" "}
                  <a
                    className="transition duration-300 hover:text-[#FA5252]"
                    href="mailto:kontakt@memati.dev"
                  >
                    kontakt@memati.dev
                  </a>
                </p>
              </div>
            </div>

            <div className="flex border-b border-[#E3E3E3] py-2.5 dark:border-[#3D3A3A]">
              <span className="socialbtn flex-shrink-0 bg-white text-[#FD7590] shadow-md dark:bg-black">
                <FaMapMarkerAlt />
              </span>
              <div className="ml-2.5 text-left">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Location
                </p>
                <p className="break-all dark:text-white">Hamburg, Germany</p>
              </div>
            </div>
          </div>

          <a
            href={cvFile}
            download
            className="mx-auto mt-6 inline-flex items-center rounded-[35px] bg-gradient-to-r from-[#DD2476] to-[#FA5252] px-8  py-3 text-lg text-white transition duration-200 ease-linear hover:bg-gradient-to-l"
          >
            <img src={downloadIcon} alt="icon" className="mr-2" />
            Download CV
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
